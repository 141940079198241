import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {AtomFormInput} from "@core/interfaces/forms/form";

@Component({
  selector: 'atom-form-toggle-button',
  templateUrl: './form-toggle-button.component.html',
  styleUrls: ['./form-toggle-button.component.scss']
})
export class FormToggleButtonComponent implements AtomFormInput {

  @Input() required: boolean = false;
  @Input({required: true}) group!: FormGroup<any>;
  @Input({required: true}) controlName!: string;
  @Input({required: true}) fieldName!: string;
  @Input() editable: boolean = true;
  @Input() nonEditLabelOverride?: string;

  @Output() inputChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() onLabel: string = 'On'
  @Input() offLabel: string = 'Off'

  emitChange(event: any) {
    this.inputChanged.emit(event)
  }
}
