import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'atom-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent implements OnInit {
  @Input({required: true}) form!: FormControl;
  @Input() fieldName!: string;

  @Input() editable: boolean = true;

  errorMessages: string[] = [];

  ngOnInit(): void {
    this.form.statusChanges.subscribe((change) => {
      this.errorMessages = [];

      if (change == "INVALID") {
        this.populateErrors();
      }
    })
  }

  populateErrors() {
    if (this.form.errors) {
      if (this.form.errors.required) {
        this.errorMessages.push(this.fieldName + " is required");
      }

      if (this.form.errors.maxlength) {
        this.errorMessages.push("Max length is " + this.form.errors.maxlength.requiredLength + ' characters');
      }

      if (this.form.errors.minlength) {
        this.errorMessages.push("Min length is " + this.form.errors.minlength.requiredLength + ' characters');
      }

      if (this.form.errors.email) {
        this.errorMessages.push("Invalid email format");
      }

      if (this.form.errors.min) {
        this.errorMessages.push("Min value is " + this.form.errors.min.min);
      }

      if (this.form.errors.max) {
        this.errorMessages.push("Max value is " + this.form.errors.max.max);
      }

      if (this.form.errors.numeric) {
        this.errorMessages.push(this.form.errors.numeric);
      }

      if (this.form.errors.alphabetical) {
        this.errorMessages.push(this.form.errors.alphabetical);
      }

      if (this.form.errors.alphaNumeric) {
        this.errorMessages.push(this.form.errors.alphaNumeric);
      }

      if (this.form.errors.ipv4WithPort) {
        this.errorMessages.push(this.form.errors.ipv4WithPort);
      }

      if (this.form.errors.wholeNumber) {
        this.errorMessages.push(this.form.errors.wholeNumber);
      }

      if (this.form.errors.apiEmail) {
        this.errorMessages.push(this.form.errors.apiEmail);
      }

      if (this.form.errors.passwordMatch) {
        this.errorMessages.push(this.form.errors.passwordMatch);
      }

      if (this.form.errors.nonEmptyArray) {
        this.errorMessages.push(this.form.errors.nonEmptyArray);
      }

      if (this.form.errors.stateSelected) {
        this.errorMessages.push(this.form.errors.stateSelected);
      }

      if (this.form.errors.optionalPhone) {
        this.errorMessages.push(this.form.errors.optionalPhone);
      }

      if (this.form.errors.endTime) {
        this.errorMessages.push(this.form.errors.endTime);
      }

      if (this.form.errors.startTime) {
        this.errorMessages.push(this.form.errors.startTime);
      }

      if (this.form.errors.firmwareVersion) {
        this.errorMessages.push(this.form.errors.firmwareVersion);
      }

      if (this.form.errors.requiredIf) {
        this.errorMessages.push(this.form.errors.requiredIf);
      }
    }
  }
}
