import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'atom-form-input-switch',
  templateUrl: './form-input-switch.component.html',
  styleUrls: ['./form-input-switch.component.scss']
})
export class FormInputSwitchComponent {
  @Input() required: boolean = false;
  @Input({required: true}) group!: FormGroup<any>;
  @Input({required: true}) controlName!: string;
  @Input({required: true}) fieldName!: string;
  @Input() editable: boolean = true;
  @Input() nonEditLabelOverride?: string;
}
