<div *ngIf="editable && !form.dirty || form.valid" class="error-container">
</div>

<ng-container *ngIf="editable && form.invalid && form.dirty">
  <ng-container *ngFor="let msg of errorMessages">
    <div class="error-container">
      <small class="error-text">{{msg}}</small>
    </div>
  </ng-container>
</ng-container>
