<form [formGroup]="group" class="configuration-input-flex-container h-full ">
  <atom-config-input-header [headerText]="fieldName"
                            [required]="required"/>
  <p-inputSwitch  *ngIf="editable"
                  [id]="controlName + '-input-switch'"
                  class="w-full mt-2"
                  [formControlName]="controlName"/>
  <ng-container *ngIf="!editable">
    <span class="enabled-label-form mt-2"
          [ngClass]="{
            'label-on':  group.controls[controlName].value,
            'label-off': !group.controls[controlName].value
          }">
      {{ nonEditLabelOverride ? nonEditLabelOverride : group.controls[controlName].value ? 'True' : 'False' }}
    </span>
  </ng-container>
</form>
