<form [formGroup]="group" class="configuration-input-flex-container h-full ">
  <atom-config-input-header [headerText]="fieldName"
                            [required]="required"/>
  <p-toggleButton *ngIf="editable"
                  id="connector-prioritized"
                  class="configuration-toggle-field w-full "
                  (onChange)="emitChange($event)"
                  [formControlName]="controlName"
                  [onLabel]="onLabel"
                  [offLabel]="offLabel"/>
  <ng-container *ngIf="!editable">
    <span class="enabled-label"
          [ngClass]="{
            'label-on':  group.controls[controlName].value,
            'label-off': !group.controls[controlName].value
          }">
      {{ nonEditLabelOverride ? nonEditLabelOverride : group.controls[controlName].value ? onLabel : offLabel }}
    </span>
  </ng-container>
</form>
