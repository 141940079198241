<ng-container *ngIf="group && controlName">
  <div class="configuration-input-flex-container">
    <atom-config-input-header [required]="required"
                              [headerText]="fieldName"
                              [fieldCounter]="fieldCounter"
                              [maxLen]="maxLen"
                              [currLen]="fieldCounter ? group.controls[controlName].value.length : 0"/>
    <div class="flex p-input-icon-right w-full">
      <i *ngIf="editable &&
              group.controls[controlName].touched &&
              group.controls[controlName].invalid &&
              inputType != InputType.dropdown"
         class="pi pi-exclamation-triangle error-color-red">
      </i>
      <ng-content [class]="contentStyle"></ng-content>
    </div>
    <atom-input-error [form]="group.controls[controlName] | formControl"
                      [editable]="editable"
                      [fieldName]="fieldName"/>
  </div>
</ng-container>
